import React from 'react';
import * as styles from './values.module.scss';
import aprendizaje from '../../../images/Home/aprendizaje.png';
import compromiso from '../../../images/Home/compromiso.png';
import compartir from '../../../images/Home/compartir.png';
import innovacion from '../../../images/Home/innovacion.png';
import { useTranslation } from "gatsby-plugin-react-i18next";

import ImgReferencia from '../../../images/Team/team.png';


const ValuesSection = () => {

    const {t} = useTranslation();

	return (
		<section className={styles.valuesSection}>
            <div className={styles.containSection}>
                <div className={styles.centerContain}>
                    <div className={styles.containTitle}
                        data-sal-delay='500'
                        data-sal='zoom-in'
                        data-sal-duration='700'
                        data-sal-easing='ease-out-back'>
                        <h2>Sobre nuestras células de trabajo</h2>
                        <p>Nuestros equipos de trabajo están compuestos por profesionales de distintas disciplinas, lo que permite lograr buenos resultados. </p>
                    </div>
                    <div className={styles.containBody}>
                        <div className={styles.containLeft}>
                            <div 
                                data-sal-delay='400'
                                data-sal='slide-right'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>
                                <img src={innovacion} alt='imagen de referencia' />
                                <h3>Autogestionados </h3>
                                <p>Las células funcionan de manera autónoma para cumplir los objetivos definidos.</p>
                            </div>
                            <div
                                data-sal-delay='400'
                                data-sal='slide-right'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>
                                <img src={aprendizaje} alt='imagen de referencia' />
                                <h3>Comprometidos</h3>
                                <p>Ponemos el foco en cumplir las fechas de entregas y mantener la calidad del software.</p>
                            </div>
                        </div>
                        <div className={styles.containRight}>
                             <div 
                                data-sal-delay='400'
                                data-sal='slide-left'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>
                                <img src={compromiso} alt='imagen de referencia' />
                                <h3>Innovadores</h3>
                                <p>En constante movimiento buscamos formas nuevas y más efectivas de dar soluciones.</p>
                            </div>
                            <div
                                data-sal-delay='400'
                                data-sal='slide-left'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>                           
                                <img src={compartir} alt='imagen de referencia' />
                                <h3>Proactivos</h3>
                                <p>Nos adelantamos a los problemas e ideamos soluciones efectivas.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.containLinks}>
                        <div className={styles.containLinksTitle} 
                            data-sal-delay='500'
                            data-sal='zoom-in'
                            data-sal-duration='700'
                            data-sal-easing='ease-out-back'>
                            <h2>Una célula de trabajo ideal</h2>      
                            <p>Para un proyecto exitoso se requieren distintas disciplinas. Nuestro equipo ideal recomendado tiene:</p>
                        </div>
                        <div className={styles.containLinksDescription}>
                            <div className={styles.contieneImg}>
                                <img src={ImgReferencia} 
                                    alt='Imagen de una computadora'/>
                            </div>
                            <div className={styles.contieneLista}>
                                <ol>
                                    <li>Project manager</li>
                                    <li>Analista funcional</li>
                                    <li>UX/UI</li>
                                    <li>Líder técnico / Arquitecto</li>
                                    <li>Desarrollador</li>
                                    <li>Analista QA</li>
                                </ol>
                            </div>  
                        </div>
                    </div>               
                </div>            
            </div>
		</section>
	);
};
export default ValuesSection;
