import React from "react";
import Layout from "../components/UI/layout";
import SEO from "../components/UI/seo";
import { graphql } from "gatsby";
import Header from "../components/Team/Header/header";
import Values from "../components/Team/Values/values";
import YourTeam from "../components/Team/YourTeam/team";
import Compani from "../components/Team/Compani/compani"
import Help from "../components/Contact/Help/help";
import Contact from "../components/Team/contact/contact";
import { useTranslation } from "react-i18next";




const Team = () => {
    const {t} = useTranslation();
    return(
      <Layout>
          <SEO title="Codeicus | tu equipo dedicado" />
          <Contact />
          <Header />
          <Values />     
          <YourTeam />
          <Compani />
          <Help t={t}/>
      </Layout>
      
    )
  }
  
  export default Team


  export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;