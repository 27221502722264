// extracted by mini-css-extract-plugin
export var bera = "compani-module--bera--c691d";
export var companiSection = "compani-module--companiSection--6b5de";
export var containCompani = "compani-module--containCompani--e5e0d";
export var containImg = "compani-module--containImg--9679f";
export var containLogo = "compani-module--containLogo--b74a2";
export var containLogoCompani = "compani-module--containLogoCompani--b408a";
export var containTitle = "compani-module--containTitle--7c0b7";
export var lenovo = "compani-module--lenovo--3c4ad";
export var lexus = "compani-module--lexus--a3c43";
export var tcfa = "compani-module--tcfa--879cb";
export var wecover = "compani-module--wecover--abec1";