// extracted by mini-css-extract-plugin
export var centerContain = "values-module--centerContain--e4d7f";
export var containBody = "values-module--containBody--aae95";
export var containLeft = "values-module--containLeft--b63f0";
export var containLinks = "values-module--containLinks--bbd12";
export var containLinksDescription = "values-module--containLinksDescription--26dad";
export var containLinksTitle = "values-module--containLinksTitle--4faff";
export var containRight = "values-module--containRight--2404b";
export var containSection = "values-module--containSection--e8adf";
export var containTitle = "values-module--containTitle--8dada";
export var contieneImg = "values-module--contieneImg--16f5b";
export var contieneLista = "values-module--contieneLista--d86e5";
export var valuesSection = "values-module--valuesSection--0df67";